export default function () {

  $('body').removeClass('f-topbar-fixed');

  // material input
  function materializeInput(input) {
    // check if the input has any value (if we've typed into it)
    if (input.val() || input.is('input:-webkit-autofill')) {
      input.closest('.formcontrol--materialize').addClass('formcontrol--hasvalue');
    } else {
      input.closest('.formcontrol--materialize').removeClass('formcontrol--hasvalue');
    }
  }

  $(function () {
    const selector = '.formcontrol--materialize .formcontrol__inner input, ' +
      '.formcontrol--materialize .formcontrol__inner textarea, ' +
      '.formcontrol--materialize .formcontrol__inner select';

    $(document)
      .on('focus', selector, function () {
        $(this).closest('.formcontrol--materialize').addClass('formcontrol--focus');
      })
      .on('change', selector, function () {
        $(this).closest('.formcontrol--materialize').addClass('formcontrol--focus');
      })
      .on('blur', selector, function () {
        $(this).closest('.formcontrol--materialize').removeClass('formcontrol--focus');
        materializeInput($(this));
      });

    $(selector).each(function () {
      materializeInput($(this));
    });
  });
}

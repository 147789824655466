const reducedMotionQueryEnabled = matchMedia('(prefers-reduced-motion)').matches;

function setEyeStyles(el, angle) {
  el.css({
    '-webkit-transform': `rotate(${angle}deg)`,
    '-moz-transform': `rotate(${angle}deg)`,
    '-ms-transform': `rotate(${angle}deg)`,
    transform: `rotate(${angle}deg)`,
  });
}

export default function () {
  if ($('.knowly-eyes__container').length && !reducedMotionQueryEnabled) {
    const eye = $('.knowly-eyes__eye');
    const x = (eye.offset().left) + (eye.width() / 2);
    const y = (eye.offset().top) + (eye.height() / 2);
    $('body').mousemove((event) => {
      const rad = Math.atan2(event.pageX - x, event.pageY - y);
      const rot = (rad * (180 / Math.PI) * -1) + 180;
      setEyeStyles(eye, rot);
    });
  }
}

import { Foundation } from 'foundation-sites/js/foundation.core';
import { Accordion } from 'foundation-sites/js/foundation.accordion';
import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
import { Tabs } from 'foundation-sites/js/foundation.tabs';
import { Tooltip } from 'foundation-sites/js/foundation.tooltip';

Foundation.plugin(Accordion, 'Accordion');
Foundation.plugin(Dropdown, 'Dropdown');
Foundation.plugin(DropdownMenu, 'DropdownMenu');
Foundation.plugin(OffCanvas, 'OffCanvas');
Foundation.plugin(Reveal, 'Reveal');
Foundation.plugin(Tabs, 'Tabs');
Foundation.plugin(Tooltip, 'Tooltip');
